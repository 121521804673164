import React, { Component } from 'react'
import Seo from '../components/seo'
import LogoColour from '../assets/images/logo-colour.svg'
import ApplicationForm from '../components/application-form'
import Link from '../utils/link'
import Fade from 'react-reveal'

class Careers extends Component {

  render() {

    return (
      <>
        <Seo title="Careers - I&D Group" description="We are currently expanding our business with a number of exciting projects on the horizon." />
        <section className='basic basic--careers'>
          <Fade bottom distance='40px'>
            <div className='basic__inner'>
              <div className='basic__content'>
                <Link to='/'><img className='basic__logo' src={LogoColour} alt='I&D Group' /></Link>

                <p>Currently Not Hiring</p>
             {/*   <p>Having secured a number of High Profile projects around Melbourne which will commence construction in the coming months, we are seeking expressions of interest from suitably qualified people to assist in building these projects.<br /><br />
                A number of these projects will commence shortly, so if you’re the right candidate, you could possibly be a member of the I&D team soon.<br /><br />
                If you're keen to join Victorias largest Concrete Structure Contractor, have qualifications in the areas below, have the relevant industry experience along with a ‘’Can Do Attitude’’, please complete the application form & we will be in contact shortly.</p>
               
                <p className='small'>These job roles include - <b>Experienced Concreters, Jump-Core Workers, Formwork Laborers, Formwork Carpenters, Site Surveyors, Experienced Mobile & Static Concrete Pump Operators, Concrete Pump Operators, Concrete labourers</b></p> */}
              </div>
            </div>
          </Fade>
        </section>
       {/* <section className='application'>
          <Fade bottom distance='40px'>
            <div className='application__inner'>
              <ApplicationForm />
            </div>
          </Fade>
        </section> */}
      </>
    )
  }
}

export default Careers