import React, { Component } from 'react'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class ApplicationForm extends Component {

  state = {
    personal_work_injury: '',
    references: '',
  }

  handleChange = e => {
    let name = e.target.name
    console.log(name)
    if (name.includes('[]')) {
      this.setState(state => {
        let array = state[name] || [];
        array = e.target.checked === true ? [...array, e.target.value] : array.filter(element => element !== e.target.value);
        return {...state, [name]: array}
      })
    } else {
      this.setState({ [name]: e.target.value })
    }
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
    .then(() => {
      this.setState({result: 'success', msg: ''})
    })
    .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'application',
      className: 'form',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    let { personal_work_injury, references } = this.state

    if (this.state.result === 'success')
      return (<p className='contact__success'>Thanks for your job application.<br /> A member of our team will be in touch soon.</p>)

    return (
      <form {...props}>
        <div className='form__section'>
          <h4>Application Form</h4>
          <div className='form__row form__row--double'>
            <div className='form__col'>
              <label>Full Name</label>
              <input type='text' name='full_name' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
            <div className='form__col'>
              <label>Email</label>
              <input type='email' name='email' placeholder='Please Enter' onChange={this.handleChange} required  />
            </div>
          </div>
          <div className='form__row form__row--double'>
            <div className='form__col'>
              <label>Address</label>
              <input type='text' name='address' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
            <div className='form__col'>
              <label>Postcode</label>
              <input type='text' name='postcode' placeholder='Please Enter' onChange={this.handleChange} required  />
            </div>
          </div>
          <div className='form__row form__row--double'>
            <div className='form__col'>
              <label>Home Number</label>
              <input type='text' name='home_number' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
            <div className='form__col'>
              <label>Mobile Number</label>
              <input type='text' name='mobile_number' placeholder='Please Enter' onChange={this.handleChange} required  />
            </div>
          </div>
          <div className='form__row form__row--double'>
            <div className='form__col'>
              <label>Date of Birth</label>
              <input type='date' name='dob' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
          </div>
        </div>
        <div className='form__section'>
          <h4>Job Details</h4>
          <div className='form__row'>
            <div className='form__col'>
              <label>Position being applied for</label>
              <input type='text' name='position_applying_for' placeholder='Please Enter' onChange={this.handleChange} required />
              <span>Please note: There is a 3 month probationary period before obtaining full time employment.</span>
            </div>
          </div>
        </div>
        <div className='form__section'>
          <h4>Licenses / Qualifications</h4>
          <div className='form__row'>
            <div className='form__col'>
              <label>Do you have any current licenses / qualifications related to this job? If yes, please list below.</label>
              <textarea name='qualifications' placeholder='Please Enter' onChange={this.handleChange} />
            </div>
          </div>
        </div>
        <div className='form__section'>
          <h4>History</h4>
          <div className="form__row">
            <label>Have you previously had a personal/work injury?</label>
            <div className="form__checkboxes">
              <div className="form__checkbox">
                <input 
                  type="radio" 
                  name="personal_work_injury"
                  id="personal_work_injury-1"
                  value="Yes"
                  onChange={this.handleChange}
                />
                <label htmlFor='personal_work_injury-1'>Yes</label>
              </div>
              <div className="form__checkbox">
                <input 
                  type="radio" 
                  name="personal_work_injury"
                  id="personal_work_injury-2"
                  value="No"
                  onChange={this.handleChange}
                />
                <label htmlFor='personal_work_injury-2'>No</label>
              </div>
            </div>
          </div>
          <div className={`form__other ${personal_work_injury === 'Yes' ? 'active' : ''}`}>
            <div className='form__row'>
              <div className='form__col'>
                <label>If yes, provide details of injury and approximate date.</label>
                <textarea name='injury_details' placeholder='Please Enter' onChange={this.handleChange} />
              </div>
            </div>
          </div>
        </div>
        <div className='form__section'>
          <h4>References</h4>
          <div className="form__row">
            <label>Do you have any I&D Group references in relation to this application?</label>
            <div className="form__checkboxes">
              <div className="form__checkbox">
                <input 
                  type="radio" 
                  name="references"
                  id="references-1"
                  value="Yes"
                  onChange={this.handleChange}
                />
                <label htmlFor='references-1'>Yes</label>
              </div>
              <div className="form__checkbox">
                <input 
                  type="radio" 
                  name="references"
                  id="references-2"
                  value="No"
                  onChange={this.handleChange}
                />
                <label htmlFor='references-2'>No</label>
              </div>
            </div>
          </div>
          <div className={`form__row form__row--hide ${references === 'Yes' ? 'active' : ''}`}>
            <div className='form__col'>
              <label>Name of Reference</label>
              <textarea name='references_details' placeholder='Please Enter' onChange={this.handleChange} />
            </div>
          </div>
        </div>
        <div className='form__section'>
          <h4>Employment History</h4>
          <div className='form__row'>
            <div className='form__col'>
              <label>Most Recent - Employer Name</label>
              <input type='text' name='most_recent_employer' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
          </div>
          <div className='form__row form__row--double'>
            <div className='form__col'>
              <label>Start Date</label>
              <input type='date' name='most_recent_date_worked_start' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
            <div className='form__col'>
              <label>End Date</label>
              <input type='date' name='most_recent_date_worked_end' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
          </div>
          <div className='form__row'>
            <div className='form__col'>
              <label>Postition Held</label>
              <input type='text' name='most_recent_position_held' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
          </div>
          <div className='form__row form__row--double'>
            <div className='form__col'>
              <label>Contact Person</label>
              <input type='text' name='most_recent_contact_person' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
            <div className='form__col'>
              <label>Contact Number</label>
              <input type='text' name='most_recent_contact_number' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
          </div>
        </div>
        <div className='form__section'>
          <div className='form__row'>
            <div className='form__col'>
              <label>2nd Most Recent - Employer Name</label>
              <input type='text' name='second_most_recent_employer' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
          </div>
          <div className='form__row form__row--double'>
            <div className='form__col'>
              <label>Start Date</label>
              <input type='date' name='second_most_recent_date_worked_start' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
            <div className='form__col'>
              <label>End Date</label>
              <input type='date' name='second_most_recent_date_worked_end' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
          </div>
          <div className='form__row'>
            <div className='form__col'>
              <label>Postition Held</label>
              <input type='text' name='second_most_recent_position_held' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
          </div>
          <div className='form__row form__row--double'>
            <div className='form__col'>
              <label>Contact Person</label>
              <input type='text' name='second_most_recent_contact_person' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
            <div className='form__col'>
              <label>Contact Number</label>
              <input type='text' name='second_most_recent_contact_number' placeholder='Please Enter' onChange={this.handleChange} required />
            </div>
          </div>
        </div>
        <div className='form__section last'>
          <h4>Employee Experience</h4>
          <div className='form__row form__row--double'>
            <div className='form__col'>
              <label>Experienced in:</label>
              <div className="form__checkboxes form__checkboxes--column">
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-1"
                    value="Foreman"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-1'>Foreman</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-2"
                    value="Leading Hand"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-2'>Leading Hand</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-3"
                    value="Decks"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-3'>Decks</label>
                </div>
                <div className={`form__padding ${this.state['experienced_in[]'] && this.state['experienced_in[]'].includes("Decks") ? 'active' : ''}`}>
                  <div className="form__checkbox">
                    <input 
                      type="checkbox" 
                      name="experienced_in[]"
                      id="experienced_in-4"
                      value="Conventional Slabs"
                      onChange={this.handleChange}
                    />
                    <label htmlFor='experienced_in-4'>Conventional Slabs</label>
                  </div>
                  <div className="form__checkbox">
                    <input 
                      type="checkbox" 
                      name="experienced_in[]"
                      id="experienced_in-5"
                      value="Tables"
                      onChange={this.handleChange}
                    />
                    <label htmlFor='experienced_in-5'>Tables</label>
                  </div>
                  <div className="form__checkbox">
                    <input 
                      type="checkbox" 
                      name="experienced_in[]"
                      id="experienced_in-6"
                      value="Aluminium Pans"
                      onChange={this.handleChange}
                    />
                    <label htmlFor='experienced_in-6'>Aluminium Pans</label>
                  </div>
                  <div className="form__checkbox">
                    <input 
                      type="checkbox" 
                      name="experienced_in[]"
                      id="experienced_in-7"
                      value="Other"
                      onChange={this.handleChange}
                    />
                    <label htmlFor='experienced_in-7'>Other</label>
                  </div>
                  <div className={`form__other ${this.state['experienced_in[]'] && this.state['experienced_in[]'].includes("Other") ? 'active' : ''}`}>
                    <input 
                      type="text" 
                      name="experienced_in_decks_other"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-8"
                    value="Columns"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-8'>Columns</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-9"
                    value="Ramps"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-9'>Ramps</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-10"
                    value="Conventional Stairs"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-10'>Conventional Stairs</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-11"
                    value="Walls"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-11'>Walls</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-12"
                    value="Cores"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-12'>Cores</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-13"
                    value="Stairmasters"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-13'>Stairmasters</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-14"
                    value="Screend Installation & Climbing"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-14'>Screend Installation & Climbing</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-15"
                    value="Precast Panel Installation"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-15'>Precast Panel Installation</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-16"
                    value="Framing"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-16'>Framing</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-17"
                    value="Bondeck, Condeck, Formdeck Installation"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-17'>Bondeck, Condeck, Formdeck Installation</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-18"
                    value="Formwork Stripping"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-18'>Formwork Stripping</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-19"
                    value="Set Outs"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-19'>Set Outs</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-20"
                    value="Levelling"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-20'>Levelling</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-21"
                    value="Reading Drawings"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-21'>Reading Drawings</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-22"
                    value="Concrete Patching / Chipping etc."
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-22'>Concrete Patching / Chipping etc.</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-23"
                    value="Hydraulic / Crane Lifted Jump Forms"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-23'>Hydraulic / Crane Lifted Jump Forms</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-24"
                    value="Steel Fixing"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-24'>Steel Fixing</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-25"
                    value="Concrete Finishing"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-25'>Concrete Finishing</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="experienced_in[]"
                    id="experienced_in-26"
                    value="Ground Works"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='experienced_in-26'>Ground Works</label>
                </div>
              </div>
            </div>
            <div className='form__col'>
              <label>Have qualifications / tickets for:</label>
              <div className="form__checkboxes form__checkboxes--column">
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-1"
                    value="Experienced Concrete Finishers"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-1'>Experienced Concrete Finishers</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-2"
                    value="High Risk"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-2'>High Risk</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-3"
                    value="EWP"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-3'>EWP</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-4"
                    value="Boom Up To 11m"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-4'>Boom Up To 11m</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-5"
                    value="Forklift"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-5'>Forklift</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-6"
                    value="Scissorlift"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-6'>Scissorlift</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-7"
                    value="Dogman"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-7'>Dogman</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-8"
                    value="Tower Crane - Operator"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-8'>Tower Crane - Operator</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-9"
                    value="Telescopic Handler / Manitou"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-9'>Telescopic Handler / Manitou</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-10"
                    value="Harness Awareness"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-10'>Harness Awareness</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-11"
                    value="Confined Space"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-11'>Confined Space</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-12"
                    value="Gas Discharge Tools"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-12'>Gas Discharge Tools</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-13"
                    value="Welding"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-13'>Welding</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-14"
                    value="Material Hoist"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-14'>Material Hoist</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-15"
                    value="Swing Stage"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-15'>Swing Stage</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-16"
                    value="First Aid"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-16'>First Aid</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-17"
                    value="OH&S"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-17'>OH&S</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-18"
                    value="Traffic Management"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-18'>Traffic Management</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-19"
                    value="Electrical Spotter"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-19'>Electrical Spotter</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-20"
                    value="Mobile Pump - Operator/Hoseman"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-20'>Mobile Pump - Operator/Hoseman</label>
                </div>
                <div className="form__checkbox">
                  <input 
                    type="checkbox" 
                    name="have_qualifications_for[]"
                    id="have_qualifications_for-21"
                    value="Tower Pump - Operator/Hoseman"
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_qualifications_for-21'>Tower Pump - Operator/Hoseman</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='form__row form__row--submit'>
          <button type='submit' className='btn btn--border'>Apply</button>
        </div>
      </form>
    )
  }
}

export default ApplicationForm